import axios from 'axios';

import urlMaps from './urlMaps';
import { getItem } from './local_storage';

const callApi = axios.create({
    baseURL: urlMaps[window.location.host]?.server,
    headers: {
        'Content-Type': 'application/json'
    }
});

let callApiWithToken = axios.create({
    baseURL: urlMaps[window.location.host]?.server,
    headers: {
        'Content-Type': 'application/json'
    }
})

let callApiWithTokenForFile = axios.create({
    baseURL: urlMaps[window.location.host]?.server,
    headers: {
        'Content-Type': 'multipart/form-data'
    }
})

callApiWithToken.interceptors.request.use(
    config => {
        const store = require('../store/store');
        config.headers['Authorization'] = `Bearer ${getItem('access-token') || store?.getState().login?.loginDetails?.accessToken}`;
        config.headers['x-refresh-token'] = getItem('refresh-token') || store?.getState().login?.loginDetails?.refreshToken;
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

callApiWithToken.interceptors.response.use(res => {
    let refreshedToken = res.headers['x-refreshed-token'];
    if (refreshedToken !== "false") {
        refreshedToken = refreshedToken?.split(" ")[1];
        localStorage.setItem('access-token', refreshedToken);
    }
    return res;
}, (err) => {
    const loggedIn = getItem("loggedIn");
    if (err?.response?.status === 401 && loggedIn === "true") {
        localStorage.clear();
        window.location.reload();
        return Promise.reject(err);
    }
    return Promise.reject(err);
});

callApiWithTokenForFile.interceptors.request.use(
    config => {
        const store = require('../store/store');
        config.headers['Authorization'] = `Bearer ${getItem('access-token') || store?.getState().login?.loginDetails?.accessToken}`;
        config.headers['x-refresh-token'] = getItem('refresh-token') || store?.getState().login?.loginDetails?.refreshToken;
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

callApiWithTokenForFile.interceptors.response.use(res => {
    let refreshedToken = res.headers['x-refreshed-token'];
    if (refreshedToken !== "false") {
        refreshedToken = refreshedToken?.split(" ")[1];
        localStorage.setItem('access-token', refreshedToken);
    }
    return res;
}, (err) => {
    const loggedIn = getItem("loggedIn");
    if (err?.response?.status === 401 && loggedIn === "true") {
        localStorage.clear();
        window.location.reload();
        return Promise.reject(err);
    }
    return Promise.reject(err);
});

export {
    callApi,
    callApiWithToken,
    callApiWithTokenForFile,
};