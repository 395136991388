import React, { useRef, useState, useEffect } from 'react'
import { round, startCase } from 'lodash'
import { Box, Divider, Flex, HStack, Img, VStack, } from '@chakra-ui/react'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'

import Typography from '../../components/Common/Typography'
import { currencyFormatter } from '../../utils/formatter'
import TransactionService from '../../services/transactionService'
import { formatDateTime } from '../../utils/formatter';

import './transaction.css';

export default function DownloadableTransactionPDF(props) {
  const { data, setLoading } = props || {};
  const pdfRef = useRef();

  const [summaryData, setSummaryData] = useState({});

  const { contactName = '', type: orderType = '', recipientEmail = '', recipientName = {}, purposeCode, paymentType, timestamps = {}, paymentAccountDetails = {}, fees = {}, fiat = {}, depositAmount = 0, depositCurrency = '', withdrawAmount = 0, withdrawCurrency = '' } = summaryData || {};
  const { firstName = '', lastName = '' } = recipientName;
  const { displayName = '', logo = '' } = paymentAccountDetails;
  const { networkFee = 0, processingFee = 0 } = fees;
  const { cxFee = 0, conversionRate = 1 } = fiat;
  let totalFee = networkFee + processingFee + cxFee;

  if (orderType === "fiat_payout")
    totalFee = round(processingFee / conversionRate, 2);

  let successTime = new Date(timestamps?.fundSettledAt || "");
  const formattedTime = formatDateTime(successTime);

  const downloadPDF = async () => {
    const input = pdfRef.current;
    html2canvas(input).then((canvas) => {
      const imageData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4', true);

      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imageWidth = canvas.width;
      const imageHeight = canvas.height;

      const ratio = Math.min(pdfWidth / imageWidth, pdfHeight / imageHeight);
      const imageX = (pdfWidth - imageWidth * ratio) / 2;

      pdf.addImage(imageData, 'PNG', imageX, 0, imageWidth * ratio, imageHeight * ratio);
      pdf.save("receipt.pdf");
    });
  }

  useEffect(() => {
    const getSummaryData = async () => {
      try {
        setLoading(true);
        const { data: apiData, response } = await TransactionService.getPayoutOrders({ filters: { orderId: data?.orderId } }) || {};
        if (apiData) {
          const { list } = apiData?.data || {};
          setSummaryData(list[0] || []);
        }
      }
      catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    }
    if (data?.orderId) {
      getSummaryData();
    }
  }, [data?.orderId])


  return (
    <>
      <Box w={"1000px"} height={"1261px"} className='pdfBody' flexDirection={"column"} ref={pdfRef} >
        <Box w={"100%"} display={"flex"} flexDirection={"row"} justifyContent={"space-between"} borderBottom={"1px solid #E4E4E4"} padding={"10px 50px 10px 50px"} >
          <Flex alignContent={"center"} justifyContent={"center"} gap={"5px"} >
            <Img src={process.env.PUBLIC_URL + "/transfi_logo.png"} h={"50px"} alt='logo' />
            <Typography type='title' color='primary' >
              TransFi
            </Typography>
          </Flex>
          <Flex mt={"15px"} >
            <Typography type='description' weight='regular' color='secondary' >
              1 of 1
            </Typography>
          </Flex>
        </Box>
        <Box mt={6} display={"flex"} flexDirection={"column"} alignItems={"center"} w={"100%"} p={"80px 100px 0px 100px"} >
          <Box display={"flex"} flexDirection={"column"} alignItems={"center"} rowGap={5} textAlign={"center"} flex={1} w={"100%"}>
            <Flex mb={6} w={"100%"} justifyContent={"space-between"} color={"white"} >
              <HStack height={"34px"}>
                <Img src={process.env.PUBLIC_URL + "/assets/approval_card_arrow.png"} alt='logo-approval' width={"47px"} height={"47px"} />
                <VStack display={"flex"} flexDirection={"column"} gap={"0px"} justifyContent={"center"} alignItems={"flex-start"} >
                  <Typography type='title' weight='medium'>
                    Payment to {`${firstName || ""} ${lastName || ""} `} ({`${displayName}`})
                  </Typography>
                  <Typography type='body' weight='medium' color='secondary' >
                    Txn Id - {summaryData?.orderId || ""}
                  </Typography>
                </VStack>
              </HStack>
              <HStack>
                <Box bg={"#26A17B"} width={"8px"} height={"8px"} borderRadius={"100%"}></Box>
                <Typography type='body' weight='medium' color='success' mb='15px' >
                  Payment Successful
                </Typography>
              </HStack>
            </Flex>

            <Flex className='transaction-summary-card' gap={"20px"} >
              <HStack w={"100%"} >
                <Typography type='body' weight='bold' color='primary' >
                  Payment Summary
                </Typography>
              </HStack>
              <HStack w={"100%"} display={"flex"} justifyContent={"space-between"} >
                <Typography type='description' weight='regular' >
                  Transaction Time
                </Typography>
                <Typography type='body' weight='regular' color='secondary' colorweight="800" >
                  {`${formattedTime}`}
                </Typography>
              </HStack>
              <HStack w={"100%"} display={"flex"} justifyContent={"space-between"} >
                <Typography type='description' weight='regular' >
                  Payment method
                </Typography>
                <Typography type='body' weight='regular' color='secondary' colorweight="800" >
                  {startCase(paymentType || "") === "Bank" ? "Bank" : "Wallet"}
                </Typography>
              </HStack>
              <HStack w={"100%"} display={"flex"} justifyContent={"space-between"} >
                <Typography type='description' weight='regular' >
                  Total paid amount
                </Typography>
                <Typography type='body' weight='regular' color='secondary' colorweight="800" >
                  {`${currencyFormatter(depositAmount || "")} ${depositCurrency || ""}`}
                </Typography>
              </HStack>
              <HStack w={"100%"} display={"flex"} justifyContent={"space-between"} >
                <Typography type='description' weight='regular' >
                  Total fee
                </Typography>
                <Typography type='body' weight='regular' color='secondary' colorweight="800" >
                  {`${currencyFormatter(totalFee)} ${depositCurrency}`}
                </Typography>
              </HStack>
              <HStack w={"100%"} display={"flex"} justifyContent={"space-between"} >
                <Typography type='description' weight='regular' >
                  They receive
                </Typography>
                <Typography type='body' weight='regular' color='secondary' colorweight="800" >
                  {`${currencyFormatter(withdrawAmount || "")} ${withdrawCurrency || ""}`}
                </Typography>
              </HStack>
              {summaryData?.type === "crypto_payout" ?
                <HStack w={"100%"} display={"flex"} justifyContent={"space-between"} >
                  <Typography type='description' weight='regular' >
                    Txn Hash
                  </Typography>
                  <Typography type='body' weight='regular' color='secondary' colorweight="800" >
                    {summaryData?.crypto?.orderId || ""}
                  </Typography>
                </HStack> :
                <HStack w={"100%"} display={"flex"} justifyContent={"space-between"} >
                  <Typography type='description' weight='regular' >
                    Bank reference number
                  </Typography>
                  <Typography type='body' weight='regular' color='secondary' colorweight="800" >
                    {summaryData?.fiat?.orderId || ""}
                  </Typography>
                </HStack>
              }
              <Divider borderWidth={"1px"} />
              <HStack w={"100%"} >
                <Typography type='body' weight='bold' color='primary' >
                  Recipient Details
                </Typography>
              </HStack>
              <HStack w={"100%"} display={"flex"} justifyContent={"space-between"} >
                <Typography type='description' weight='regular' >
                  Contact
                </Typography>
                <VStack display={"flex"} gap="0px" >
                  <HStack>
                    <Img src={process.env.PUBLIC_URL + logo} alt='contactLogo' w={"25px"} h={"18px"} />
                    <Typography type='body' weight='regular' color='secondary' colorweight="800" >
                      {contactName}
                    </Typography>
                  </HStack>
                  <Typography type='caption' weight='regular' color='secondary' colorweight="400" >
                    {recipientEmail}
                  </Typography>
                </VStack>
              </HStack>
              <HStack w={"100%"} display={"flex"} justifyContent={"space-between"} >
                <Typography type='description' weight='regular' >
                  Payment Account
                </Typography>
                <Typography type='body' weight='regular' color='secondary' colorweight="800" >
                  {`${depositCurrency} Account`}
                </Typography>
              </HStack>
            </Flex>

            <Flex className='transaction-summary-card' mb={"10px"}>
              <HStack w={"100%"} >
                <Typography type='body' weight='bold' color='primary' >
                  Additional Details
                </Typography>
              </HStack>
              <HStack w={"100%"} display={"flex"} justifyContent={"space-between"} mt={"20px"} >
                <Typography type='description' weight='regular' >
                  Payment purpose
                </Typography>
                <Typography type='body' weight='regular' color='secondary' colorweight="800" >
                  {purposeCode}
                </Typography>
              </HStack>
            </Flex>
          </Box>
        </Box>

        <Box w={"1000px"} position={"absolute"} bottom={"30px"} padding={"10px 20px 15px 20px"} textAlign={"center"} borderTop={"1px solid #E4E4E4"} borderBottom={"1px solid grey"} >
          <Typography type='description' weight='regular' color='secondary' >
            Services are provided by Trans-Fi UAB (registration no. 306117433) with an address at Pramones 10G, Vilnius, Lithuania - 11118.
            Lithuania operating under virtual asset service provider licenses issued by Financial Crime Investigation Service of Lithuania.
            <br />
            <br />
            Copyright ©2024 Trans-Fi UAB | All Rights Reserved
          </Typography>
        </Box>

      </Box>
      <button onClick={downloadPDF} id='pdf-download-button' style={{ visibility: "hidden" }} >Click me</button>
    </>
  )
}