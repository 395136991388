import { isEmpty, isUndefined } from 'lodash';
import { sepaCountries } from '../../../../constants'
const formFields = (paymentGateway = '', currency, paymentCode = 'default') => {

    const bitssa = {
        PHP: {
            default: [
                {
                    control: "input",
                    name: "beneficiaryName",
                    label: "Name",
                    placeholder: "Beneficiary name",
                },
                {
                    control: "input",
                    name: "swiftbic",
                    label: "SWIFT BIC",
                    placeholder: "SWIFT BIC CODE",
                },
                {
                    control: "select",
                    name: "gender",
                    label: "Gender",
                    placeholder: "Select gender",
                    options: [{ label: "Male", value: "Male" }, { label: "Female", value: "Female" }]
                },
                {
                    control: "input",
                    name: "mobNumber",
                    label: "Mobile",
                    placeholder: "Beneficiary mobile number",
                    type: 'number'
                },
                {
                    control: "input",
                    name: "address",
                    label: "Address",
                    placeholder: "Beneficiary address",
                },
                {
                    control: "input",
                    name: "addressstate",
                    label: "State",
                    placeholder: "Address state",
                },
                {
                    control: "input",
                    name: "city",
                    label: "City",
                    placeholder: "Beneficiary city",
                },
                {
                    control: "input",
                    name: "postcode",
                    label: "PostCode / Zip",
                    placeholder: "Beneficiary post code",
                },
            ]
        },
        INR: {
            default: [
                {
                    control: "input",
                    name: "beneficiaryName",
                    label: "Name",
                    placeholder: "Beneficiary name",
                },
                {
                    control: "input",
                    name: "swiftbic",
                    label: "IFSC Code",
                    placeholder: "IFSC Code",
                },
                {
                    control: "select",
                    name: "gender",
                    label: "Gender",
                    placeholder: "Select gender",
                    options: [{ label: "Male", value: "Male" }, { label: "Female", value: "Female" }]
                },
                {
                    control: "input",
                    name: "mobNumber",
                    label: "Mobile",
                    placeholder: "Beneficiary mobile number",
                    type: 'number'
                },
                {
                    control: "input",
                    name: "address",
                    label: "Address",
                    placeholder: "Beneficiary address",
                },
                {
                    control: "input",
                    name: "addressstate",
                    label: "State",
                    placeholder: "Address state",
                },
                {
                    control: "input",
                    name: "city",
                    label: "City",
                    placeholder: "Beneficiary city",
                },
                {
                    control: "input",
                    name: "postcode",
                    label: "PostCode / Zip",
                    placeholder: "Beneficiary post code",
                }
            ]
        }
    }

    const directa24 = {
        BRL: {
            default: [
                {
                    control: "input",
                    name: "bank_branch",
                    label: "Bank Branch",
                    placeholder: "eg: 499",
                },
                {
                    control: "select",
                    name: "account_type",
                    label: "Account Type",
                    placeholder: "Account Type",
                    options: [
                        {
                            "label": "Current Account",
                            "value": "C",
                        },
                        {
                            "label": "Savings Account",
                            "value": "S",
                        },
                    ]
                },
                {
                    control: "input",
                    name: "beneficiary_name",
                    label: "Beneficiary First Name",
                    placeholder: "Enter First Name",
                },
                {
                    control: "input",
                    name: "beneficiary_lastname",
                    label: "Beneficiary Last Name",
                    placeholder: "Enter Last Name",
                },
                {
                    control: "select",
                    name: "document_type",
                    label: "Document Type",
                    placeholder: "Select Document Type",
                    options: [
                        {
                            "label": "CPF",
                            "value": "CPF",
                        }
                    ]
                },
                {
                    control: "input",
                    name: "document_id",
                    label: "Document Id",
                    placeholder: "Enter Document Id",
                }
            ]
        },
        CLP: {
            default: [
                {
                    control: "select",
                    name: "account_type",
                    label: "Account Type",
                    placeholder: "Select Acount Type",
                    options: [
                        {
                            "label": "Current Account",
                            "value": "C",
                        },
                        {
                            "label": "Savings account",
                            "value": "S",
                        },
                    ]
                },
                {
                    control: "input",
                    name: "beneficiary_name",
                    label: "Beneficiary First Name",
                    placeholder: "Enter First Name",
                },
                {
                    control: "input",
                    name: "beneficiary_lastname",
                    label: "Beneficiary Last Name",
                    placeholder: "Enter Last Name",
                },
                {
                    control: "select",
                    name: "document_type",
                    label: "Document Type",
                    placeholder: "Select Document Type",
                    options: [
                        {
                            "label": "RUT",
                            "value": "RUT",
                        }
                    ]
                },
                {
                    control: "input",
                    name: "document_id",
                    label: "Document Id",
                    placeholder: "Enter Document Id",
                }
            ]
        },
        MXN: {
            default: [
                {
                    control: "select",
                    name: "account_type",
                    label: "Account Type",
                    placeholder: "Select Acount Type",
                    options: [
                        {
                            "label": "Current Account",
                            "value": "C",
                        },
                        {
                            "label": "Savings account",
                            "value": "S",
                        },
                    ]
                },
                {
                    control: "input",
                    name: "beneficiary_name",
                    label: "Beneficiary First Name",
                    placeholder: "Enter First Name",
                },
                {
                    control: "input",
                    name: "beneficiary_lastname",
                    label: "Beneficiary Last Name",
                    placeholder: "Enter Last Name",
                },
                {
                    control: "select",
                    name: "document_type",
                    label: "Document Type",
                    placeholder: "Select Document Type",
                    options: [
                        {
                            "label": "CURP",
                            "value": "CURP",
                        },
                        {
                            "label": "PASS",
                            "value": "PASS",
                        }
                    ]
                },
                {
                    control: "input",
                    name: "document_id",
                    label: "Document Id",
                    placeholder: "Enter Document Id",
                }
            ]
        },
        COP: {
            default: [
                {
                    control: "select",
                    name: "account_type",
                    label: "Account Type",
                    placeholder: "Select Acount Type",
                    options: [
                        {
                            "label": "Current Account",
                            "value": "C",
                        },
                        {
                            "label": "Savings account",
                            "value": "S",
                        },
                    ]
                },
                {
                    control: "input",
                    name: "beneficiary_name",
                    label: "Beneficiary First Name",
                    placeholder: "Enter First Name",
                    allowedContactTypes: [
                        "Individual",
                    ]
                },
                {
                    control: "input",
                    name: "beneficiary_lastname",
                    label: "Company Name",
                    placeholder: "Enter Company Name",
                    allowedContactTypes: [
                        "Business",
                    ]
                },
                {
                    control: "input",
                    name: "beneficiary_lastname",
                    label: "Beneficiary Last Name",
                    placeholder: "Enter Last name",
                    allowedContactTypes: [
                        "Individual",
                    ]
                },
                {
                    control: "input",
                    name: 'address',
                    label: "Address",
                    placeholder: "Enter Address",
                },
                {
                    control: "select",
                    name: "document_type",
                    label: "Document Type",
                    placeholder: "Select Document Type",
                    options: [
                        {
                            "label": "CC",
                            "value": "CC",
                        },
                        {
                            "label": "PASS",
                            "value": "PASS",
                        },
                    ]
                },
                {
                    control: "input",
                    name: "document_id",
                    label: "Document Id",
                    placeholder: "Enter Document Id",
                },
                {
                    control: "input",
                    name: "bic",
                    label: "Swift bic number",
                    placeholder: "Enter swift/bic number",
                }
            ]
        },
        THB: {
            default: [
                {
                    control: "select",
                    name: "account_type",
                    label: "Account Type",
                    placeholder: "Select Acount Type",
                    options: [
                        {
                            "label": "Current Account",
                            "value": "C",
                        },
                        {
                            "label": "Savings account",
                            "value": "S",
                        },
                    ]
                },
                {
                    control: "input",
                    name: "beneficiary_name",
                    label: "Beneficiary First Name",
                    placeholder: "Enter First Name",
                },
                {
                    control: "input",
                    name: "beneficiary_lastname",
                    label: "Beneficiary Last Name",
                    placeholder: "Enter Last Name",
                },
                {
                    control: "select",
                    name: "document_type",
                    label: "Document Type",
                    placeholder: "Select Document Type",
                    options: [
                        {
                            "label": "ID",
                            "value": "ID",
                        }
                    ]
                },
                {
                    control: "input",
                    name: "document_id",
                    label: "Document Id",
                    placeholder: "Enter Document Id",
                }
            ]
        },
        JPY: {
            default: [
                {
                    control: "input",
                    name: "bank_branch",
                    label: "Bank Branch",
                    placeholder: "eg: 499",
                },
                {
                    control: "select",
                    name: "account_type",
                    label: "Account Type",
                    placeholder: "Select Acount Type",
                    options: [
                        {
                            "label": "Current Account",
                            "value": "C",
                        },
                        {
                            "label": "Savings account",
                            "value": "S",
                        },
                        {
                            "label": "Other",
                            "value": "O",
                        }
                    ]
                },
                {
                    control: "input",
                    name: "beneficiary_name",
                    label: "Beneficiary First Name",
                    placeholder: "Enter First Name",
                },
                {
                    control: "input",
                    name: "beneficiary_lastname",
                    label: "Beneficiary Last Name",
                    placeholder: "Enter Last Name",
                },
                {
                    control: "select",
                    name: "document_type",
                    label: "Document Type",
                    placeholder: "Select Document Type",
                    options: [
                        {
                            "label": "ID",
                            "value": "ID",
                        },
                        {
                            "label": "DL",
                            "value": "DL",
                        },
                        {
                            "label": "PASS",
                            "value": "PASS",
                        },
                        {
                            "label": "RD",
                            "value": "RD",
                        },
                    ]
                },
                {
                    control: "input",
                    name: "document_id",
                    label: "Document Id",
                    placeholder: "Enter Document Id",
                }
            ]
        },
    }

    const openpayd = {
        EUR: {
            default: [
                {
                    control: "input",
                    name: "iban",
                    label: "IBAN",
                    placeholder: "IBAN",
                },
                {
                    control: "input",
                    name: "bic",
                    label: "BIC",
                    placeholder: "BIC",
                },
                {
                    control: "select",
                    name: "country",
                    label: "Bank Account Country",
                    placeholder: "Select Bank Account Country",
                    options: Object.keys(sepaCountries).map((ele) => { return { label: ele, value: sepaCountries[ele] } })
                }
            ]
        },
        GBP: {
            default: [
                {
                    control: "input",
                    name: "iban",
                    label: "IBAN",
                    placeholder: "IBAN",
                },
                {
                    control: "input",
                    name: "bic",
                    label: "BIC",
                    placeholder: "BIC",
                },
                {
                    control: "select",
                    name: "country",
                    label: "Bank Account Country",
                    placeholder: "Select Bank Account Country",
                    options: Object.keys(sepaCountries).map((ele) => { return { label: ele, value: sepaCountries[ele] } })
                }
            ]
        }
    }

    const peer = {
        SGD: {
            default: [
                {
                    control: "input",
                    name: "beneficiary_name",
                    label: "Beneficiary Name ( As per bank record )",
                    placeholder: "Beneficiary name",
                },
            ]
        }
    }

    const dlocal = {
        MXN: {
            default: [
                {
                    control: "select",
                    name: "account_type",
                    label: "Account Type",
                    placeholder: "Select Acount Type",
                    options: [
                        {
                            "label": "Current Account",
                            "value": "C",
                        },
                        {
                            "label": "Savings account",
                            "value": "S",
                        },
                    ]
                },
                {
                    control: "input",
                    name: "beneficiary_name",
                    label: "Beneficiary First Name",
                    placeholder: "Enter First Name",
                },
                {
                    control: "input",
                    name: "beneficiary_lastname",
                    label: "Beneficiary Last Name",
                    placeholder: "Enter Last Name",
                },
                {
                    control: "select",
                    name: "document_type",
                    label: "Document Type",
                    placeholder: "Select Document Type",
                    options: [
                        {
                            "label": "CURP",
                            "value": "CURP",
                        },
                        {
                            "label": "PASS",
                            "value": "PASS",
                        }
                    ]
                },
                {
                    control: "input",
                    name: "document_id",
                    label: "Document Id",
                    placeholder: "Enter Document Id",
                }
            ]
        },
        ARS: {
            default: [
                {
                    control: "select",
                    name: "account_type",
                    label: "Account Type",
                    placeholder: "Select Acount Type",
                    options: [
                        {
                            "label": "Current Account",
                            "value": "C",
                        },
                        {
                            "label": "Savings account",
                            "value": "S",
                        },
                    ]
                },
                {
                    control: "input",
                    name: "beneficiary_name",
                    label: "Beneficiary First Name",
                    placeholder: "Enter First Name",
                },
                {
                    control: "input",
                    name: "beneficiary_lastname",
                    label: "Beneficiary Last Name",
                    placeholder: "Enter Last Name",
                },
                {
                    control: "select",
                    name: "document_type",
                    label: "Document Type",
                    placeholder: "Select Document Type",
                    options: [
                        {
                            "label": "CUIL",
                            "value": "CUIL",
                        }
                    ]
                },
                {
                    control: "input",
                    name: "document_id",
                    label: "Document Id",
                    placeholder: "Enter Document Id",
                }
            ]
        },
        BRL: {
            default: [
                {
                    control: "input",
                    name: "bank_branch",
                    label: "Bank Branch",
                    placeholder: "eg: 499",
                },
                {
                    control: "select",
                    name: "account_type",
                    label: "Account Type",
                    placeholder: "Select Acount Type",
                    options: [
                        {
                            "label": "Current Account",
                            "value": "C",
                        },
                        {
                            "label": "Savings account",
                            "value": "S",
                        },
                    ]
                },
                {
                    control: "input",
                    name: "beneficiary_name",
                    label: "Beneficiary First Name",
                    placeholder: "Enter First Name",
                },
                {
                    control: "input",
                    name: "beneficiary_lastname",
                    label: "Beneficiary Last Name",
                    placeholder: "Enter Last Name",
                },
                {
                    control: "select",
                    name: "document_type",
                    label: "Document Type",
                    placeholder: "Select Document Type",
                    options: [
                        {
                            "label": "CPF",
                            "value": "CPF",
                        }
                    ]
                },
                {
                    control: "input",
                    name: "document_id",
                    label: "Document Id",
                    placeholder: "Enter Document Id",
                }
            ]
        },
        CLP: {
            default: [
                {
                    control: "select",
                    name: "account_type",
                    label: "Account Type",
                    placeholder: "Select Acount Type",
                    options: [
                        {
                            "label": "Current Account",
                            "value": "C",
                        },
                        {
                            "label": "Savings account",
                            "value": "S",
                        },
                    ]
                },
                {
                    control: "input",
                    name: "beneficiary_name",
                    label: "Beneficiary First Name",
                    placeholder: "Enter First Name",
                },
                {
                    control: "input",
                    name: "beneficiary_lastname",
                    label: "Beneficiary Last Name",
                    placeholder: "Enter Last Name",
                },
                {
                    control: "select",
                    name: "document_type",
                    label: "Document Type",
                    placeholder: "Select Document Type",
                    options: [
                        {
                            "label": "RUT",
                            "value": "RUT",
                        }
                    ]
                },
                {
                    control: "input",
                    name: "document_id",
                    label: "Document Id",
                    placeholder: "Enter Document Id",
                }
            ]
        },
        ZAR: {
            default: [
                {
                    control: "select",
                    name: "account_type",
                    label: "Account Type",
                    placeholder: "Select Acount Type",
                    options: [
                        {
                            "label": "Current Account",
                            "value": "C",
                        },
                        {
                            "label": "Savings account",
                            "value": "S",
                        },
                    ]
                },
                {
                    control: "input",
                    name: "beneficiary_name",
                    label: "Beneficiary First Name",
                    placeholder: "Enter First Name",
                },
                {
                    control: "input",
                    name: "beneficiary_lastname",
                    label: "Beneficiary Last Name",
                    placeholder: "Enter Last Name",
                },
                {
                    control: "select",
                    name: "document_type",
                    label: "Document Type",
                    placeholder: "Select Document Type",
                    options: [
                        {
                            "label": "ID",
                            "value": "ID",
                        }
                    ]
                },
                {
                    control: "input",
                    name: "document_id",
                    label: "Document Id",
                    placeholder: "Enter Document Id",
                }
            ]
        },
        COP: {
            default: [
                {
                    control: "select",
                    name: "account_type",
                    label: "Account Type",
                    placeholder: "Select Acount Type",
                    options: [
                        {
                            "label": "Current Account",
                            "value": "C",
                        },
                        {
                            "label": "Savings account",
                            "value": "S",
                        },
                    ]
                },
                {
                    control: "input",
                    name: "beneficiary_name",
                    label: "Beneficiary First Name",
                    placeholder: "Enter First Name",
                    allowedContactTypes: [
                        "Individual",
                    ]
                },
                {
                    control: "input",
                    name: "beneficiary_lastname",
                    label: "Company Name",
                    placeholder: "Enter Company Name",
                    allowedContactTypes: [
                        "Business",
                    ]
                },
                {
                    control: "input",
                    name: "beneficiary_lastname",
                    label: "Beneficiary Last Name",
                    placeholder: "Enter Last name",
                    allowedContactTypes: [
                        "Individual",
                    ]
                },
                {
                    control: "input",
                    name: 'address',
                    label: "Address",
                    placeholder: "Enter Address",
                },
                {
                    control: "select",
                    name: "document_type",
                    label: "Document Type",
                    placeholder: "Select Document Type",
                    options: [
                        {
                            "label": "CC",
                            "value": "CC",
                        },
                        {
                            "label": "PASS",
                            "value": "PASS",
                        },
                    ]
                },
                {
                    control: "input",
                    name: "document_id",
                    label: "Document Id",
                    placeholder: "Enter Document Id",
                }
            ]
        },
    }

    const yellowcard = [
        {
            control: "input",
            name: "beneficiary_name",
            label: "Beneficiary Name",
            placeholder: "Beneficiary name",
        },
        {
            control: "select",
            name: "id_type",
            label: "Select Id type:",
            placeholder: "Select Id",
            options: [{ label: "Identity Card", value: "identity_card" }, { label: "Driving License", value: "driving_license" }, { label: "Passport", value: "passport" }],
        },
        {
            control: "input",
            name: "id_number",
            label: "Id number",
            placeholder: "Id number",
        },
        {
            control: "input",
            name: "address",
            label: "Address",
            placeholder: "Address",
        },
    ];

    const bitso = {
        ARS: {
            default: [
                {
                    control: "input",
                    name: "beneficiary_name",
                    label: "Beneficiary First Name",
                    placeholder: "Enter First Name",
                },
                {
                    control: "input",
                    name: "beneficiary_lastname",
                    label: "Beneficiary Last Name",
                    placeholder: "Enter Last Name",
                },
            ]
        },
        COP: {
            default: [
                {
                    control: "select",
                    name: "account_type",
                    label: "Account Type",
                    placeholder: "Select Acount Type",
                    options: [
                        {
                            "label": "Checking Account",
                            "value": "1",
                        },
                        {
                            "label": "Savings Account",
                            "value": "2",
                        },
                    ]
                },
                {
                    control: "input",
                    name: "beneficiary_name",
                    label: "Beneficiary First Name",
                    placeholder: "Enter First Name",
                    allowedContactTypes: [
                        "Individual",
                    ]
                },
                {
                    control: "input",
                    name: "beneficiary_lastname",
                    label: "Company Name",
                    placeholder: "Enter Company Name",
                    allowedContactTypes: [
                        "Business",
                    ]
                },
                {
                    control: "input",
                    name: "beneficiary_lastname",
                    label: "Beneficiary Last Name",
                    placeholder: "Enter Last name",
                    allowedContactTypes: [
                        "Individual",
                    ]
                },
                {
                    control: "select",
                    name: "document_type",
                    label: "Document Type",
                    placeholder: "Select Document Type",
                    options: [
                        {
                            "label": "CC",
                            "value": "CC",
                        },
                        {
                            "label": "CE",
                            "value": "CE",
                        },
                        {
                            "label": "NIT",
                            "value": "NIT",
                        },
                        {
                            "label": "PASS",
                            "value": "PASS",
                        },
                    ]
                },
                {
                    control: "input",
                    name: "document_id",
                    label: "Document Id",
                    placeholder: "Enter Document Id",
                }
            ]
        }
    }

    const tripleA = {
        ARS: {
            default: [
                {
                    control: "input",
                    name: "beneficiary_name",
                    label: "Beneficiary Name",
                    placeholder: "Beneficiary name",
                },
                {
                    control: "select",
                    name: "bank_account_type",
                    label: "Select your bank account type",
                    placeholder: "Select your bank account type",
                    options: [{ "label": "Checking account", "value": "CHECKING" }, { "label": "Savings account", "value": "SAVINGS" }]
                },
                {
                    control: "select",
                    name: "document_type",
                    label: "Document Type",
                    placeholder: "Select Document Type",
                    options: [
                        {
                            "label": "TAX ID",
                            "value": "TAX_ID",
                        }
                    ]
                },
                {
                    control: "input",
                    name: "document_id",
                    label: "Document Id",
                    placeholder: "Eg: 20-23454456-7. CUIT/CUIL/CDI are accepted",
                },
                {
                    control: "input",
                    name: "mobile_number",
                    label: "Enter your mobile number",
                    placeholder: "Eg: 92213033335",
                },
            ]
        },
        COP: {
            default: [
                {
                    control: "input",
                    name: "beneficiary_name",
                    label: "Beneficiary First Name",
                    placeholder: "Enter First Name",
                    allowedContactTypes: [
                        "Individual",
                    ]
                },
                {
                    control: "input",
                    name: "beneficiary_lastname",
                    label: "Company Name",
                    placeholder: "Enter Company Name",
                    allowedContactTypes: [
                        "Business",
                    ]
                },
                {
                    control: "input",
                    name: "beneficiary_lastname",
                    label: "Beneficiary Last Name",
                    placeholder: "Enter Last name",
                    allowedContactTypes: [
                        "Individual",
                    ]
                },
                {
                    control: "select",
                    name: "account_type",
                    label: "Account Type",
                    placeholder: "Select Acount Type",
                    options: [
                        {
                            "label": 'Checking',
                            "value": "checking",
                        },
                        {
                            "label": "Savings",
                            "value": "savings",
                        },
                    ]
                },
                {
                    control: "select",
                    name: "document_type",
                    label: "Document Type",
                    placeholder: "Select Document Type",
                    options: [
                        {
                            "label": "CED_CIU",
                            "value": "CED_CIU",
                        },
                        {
                            "label": "NIT",
                            "value": "NIT",
                        }
                    ]
                },
                {
                    control: "input",
                    name: "document_id",
                    label: "Document Id",
                    placeholder: "Enter Document Id",
                },
                {
                    control: "input",
                    name: "mobNumber",
                    label: "Mobile",
                    placeholder: "Beneficiary mobile number",
                    type: 'number'
                },
                {
                    control: "input",
                    name: "address",
                    label: "Address",
                    placeholder: "Beneficiary address",
                },
                {
                    control: "input",
                    name: "addressstate",
                    label: "State",
                    placeholder: "Address state",
                },
                {
                    control: "input",
                    name: "city",
                    label: "City",
                    placeholder: "Beneficiary city",
                },
                {
                    control: "input",
                    name: "postcode",
                    label: "PostCode / Zip",
                    placeholder: "Beneficiary post code",
                }
            ]
        },
    }

    const koywe = {
        CLP: {
            default: [
                {
                    control: "input",
                    name: "beneficiary_name",
                    label: "Beneficiary First Name",
                    placeholder: "Enter First Name",
                },
                {
                    control: "input",
                    name: "beneficiary_lastname",
                    label: "Beneficiary Last Name",
                    placeholder: "Enter Last name",
                },
                {
                    control: "select",
                    name: "account_type",
                    label: "Account Type",
                    placeholder: "Select Acount Type",
                    options: [
                        {
                            "label": 'Checking',
                            "value": "checking",
                        },
                        {
                            "label": "Savings",
                            "value": "savings",
                        },
                    ]
                },
                {
                    control: "select",
                    name: "document_type",
                    label: "Document Type",
                    placeholder: "Select Document Type",
                    options: [
                        {
                            "label": "RUT",
                            "value": "RUT",
                        }
                    ]
                },
                {
                    control: "input",
                    name: "document_id",
                    label: "Document Id",
                    placeholder: "Enter Document Id",
                },
                {
                    control: "input",
                    name: "mobNumber",
                    label: "Mobile",
                    placeholder: "Beneficiary mobile number",
                    type: 'number'
                },
                {
                    control: "input",
                    name: "address",
                    label: "Address",
                    placeholder: "Beneficiary address",
                },
                {
                    control: "input",
                    name: "addressstate",
                    label: "State",
                    placeholder: "Address state",
                },
                {
                    control: "input",
                    name: "city",
                    label: "City",
                    placeholder: "Beneficiary city",
                },
                {
                    control: "input",
                    name: "postcode",
                    label: "PostCode / Zip",
                    placeholder: "Beneficiary post code",
                },
            ]
        },
        COP: {
            default: [
                {
                    control: "input",
                    name: "beneficiary_name",
                    label: "Beneficiary First Name",
                    placeholder: "Enter First Name",
                    allowedContactTypes: [
                        "Individual",
                    ]
                },
                {
                    control: "input",
                    name: "beneficiary_lastname",
                    label: "Company Name",
                    placeholder: "Enter Company Name",
                    allowedContactTypes: [
                        "Business",
                    ]
                },
                {
                    control: "input",
                    name: "beneficiary_lastname",
                    label: "Beneficiary Last Name",
                    placeholder: "Enter Last name",
                    allowedContactTypes: [
                        "Individual",
                    ]
                },
                {
                    control: "select",
                    name: "account_type",
                    label: "Account Type",
                    placeholder: "Select Acount Type",
                    options: [
                        {
                            "label": 'Checking',
                            "value": "checking",
                        },
                        {
                            "label": "Savings",
                            "value": "savings",
                        },
                    ]
                },
                {
                    control: "select",
                    name: "document_type",
                    label: "Document Type",
                    placeholder: "Select Document Type",
                    options: [
                        {
                            "label": "CED_CIU",
                            "value": "CED_CIU",
                        },
                        {
                            "label": "NIT",
                            "value": "NIT",
                        },
                        {
                            "label": "CED_EXT",
                            "value": "CED_EXT",
                        },
                        {
                            "label": "RUT",
                            "value": "RUT",
                        },
                        {
                            "label": "CUIT",
                            "value": "CUIT",
                        },
                        {
                            "label": "DNI",
                            "value": "DNI",
                        },
                        {
                            "label": "RUC",
                            "value": "RUC",
                        }
                    ]
                },
                {
                    control: "input",
                    name: "document_id",
                    label: "Document Id",
                    placeholder: "Enter Document Id",
                },
                {
                    control: "input",
                    name: "mobNumber",
                    label: "Mobile",
                    placeholder: "Beneficiary mobile number",
                    type: 'number'
                },
                {
                    control: "input",
                    name: "address",
                    label: "Address",
                    placeholder: "Beneficiary address",
                },
                {
                    control: "input",
                    name: "addressstate",
                    label: "State",
                    placeholder: "Address state",
                },
                {
                    control: "input",
                    name: "city",
                    label: "City",
                    placeholder: "Beneficiary city",
                },
                {
                    control: "input",
                    name: "postcode",
                    label: "PostCode / Zip",
                    placeholder: "Beneficiary post code",
                }
            ]
        },
        MXN: {
            default: [
                {
                    control: "input",
                    name: "beneficiary_name",
                    label: "Beneficiary First Name",
                    placeholder: "Enter First Name",
                },
                {
                    control: "input",
                    name: "beneficiary_lastname",
                    label: "Beneficiary Last Name",
                    placeholder: "Enter Last Name",
                },
                {
                    control: "select",
                    name: "account_type",
                    label: "Account Type",
                    placeholder: "Select Acount Type",
                    options: [
                        {
                            "label": 'Checking',
                            "value": "checking",
                        },
                        {
                            "label": "Savings",
                            "value": "savings",
                        },
                    ]
                },
                {
                    control: "select",
                    name: "document_type",
                    label: "Document Type",
                    placeholder: "Select Document Type",
                    options: [
                        {
                            "label": "CURP",
                            "value": "CURP",
                        },
                    ]
                },
                {
                    control: "input",
                    name: "document_id",
                    label: "Document Id",
                    placeholder: "Enter Document Id",
                },
                {
                    control: "input",
                    name: "mobNumber",
                    label: "Mobile",
                    placeholder: "Beneficiary mobile number",
                    type: 'number'
                },
                {
                    control: "input",
                    name: "address",
                    label: "Address",
                    placeholder: "Beneficiary address",
                },
                {
                    control: "input",
                    name: "addressstate",
                    label: "State",
                    placeholder: "Address state",
                },
                {
                    control: "input",
                    name: "city",
                    label: "City",
                    placeholder: "Beneficiary city",
                },
                {
                    control: "input",
                    name: "postcode",
                    label: "PostCode / Zip",
                    placeholder: "Beneficiary post code",
                },
                {
                    control: "input",
                    name: "bic",
                    label: "Swift bic number",
                    placeholder: "Enter swift/bic number",
                }
            ]
        },
        ARS: {
            default: [
                {
                    control: "input",
                    name: "beneficiary_name",
                    label: "Beneficiary First Name",
                    placeholder: "Enter First Name",
                },
                {
                    control: "input",
                    name: "beneficiary_lastname",
                    label: "Beneficiary Last Name",
                    placeholder: "Enter Last name",
                },
                {
                    control: "select",
                    name: "account_type",
                    label: "Account Type",
                    placeholder: "Select Acount Type",
                    options: [
                        {
                            "label": 'Checking',
                            "value": "checking",
                        },
                        {
                            "label": "Savings",
                            "value": "savings",
                        },
                    ]
                },
                {
                    control: "select",
                    name: "document_type",
                    label: "Document Type",
                    placeholder: "Select Document Type",
                    options: [
                        {
                            "label": "CUIT",
                            "value": "CUIT",
                        },
                    ]
                },
                {
                    control: "input",
                    name: "document_id",
                    label: "Document Id",
                    placeholder: "Enter Document Id",
                },
                {
                    control: "input",
                    name: "mobNumber",
                    label: "Mobile",
                    placeholder: "Beneficiary mobile number",
                    type: 'number'
                },
                {
                    control: "input",
                    name: "address",
                    label: "Address",
                    placeholder: "Beneficiary address",
                },
                {
                    control: "input",
                    name: "addressstate",
                    label: "State",
                    placeholder: "Address state",
                },
                {
                    control: "input",
                    name: "city",
                    label: "City",
                    placeholder: "Beneficiary city",
                },
                {
                    control: "input",
                    name: "postcode",
                    label: "PostCode / Zip",
                    placeholder: "Beneficiary post code",
                },
            ]
        },
        PEN: {
            default: [
                {
                    control: "input",
                    name: "beneficiary_name",
                    label: "Beneficiary First Name",
                    placeholder: "Enter First Name",
                },
                {
                    control: "input",
                    name: "beneficiary_lastname",
                    label: "Beneficiary Last Name",
                    placeholder: "Enter Last name",
                },
                {
                    control: "select",
                    name: "account_type",
                    label: "Account Type",
                    placeholder: "Select Acount Type",
                    options: [
                        {
                            "label": 'Checking',
                            "value": "checking",
                        },
                        {
                            "label": "Savings",
                            "value": "savings",
                        },
                    ]
                },
                {
                    control: "select",
                    name: "document_type",
                    label: "Document Type",
                    placeholder: "Select Document Type",
                    options: [
                        {
                            "label": "RUC",
                            "value": "RUC",
                        },
                    ]
                },
                {
                    control: "input",
                    name: "document_id",
                    label: "Document Id",
                    placeholder: "Enter Document Id",
                },
                {
                    control: "input",
                    name: "mobNumber",
                    label: "Mobile",
                    placeholder: "Beneficiary mobile number",
                    type: 'number'
                },
                {
                    control: "input",
                    name: "address",
                    label: "Address",
                    placeholder: "Beneficiary address",
                },
                {
                    control: "input",
                    name: "addressstate",
                    label: "State",
                    placeholder: "Address state",
                },
                {
                    control: "input",
                    name: "city",
                    label: "City",
                    placeholder: "Beneficiary city",
                },
                {
                    control: "input",
                    name: "postcode",
                    label: "PostCode / Zip",
                    placeholder: "Beneficiary post code",
                },
            ]
        },
    }


    const local_payment = {
        COP: {
            default: [
                {
                    control: "input",
                    name: "beneficiary_name",
                    label: "Beneficiary First Name",
                    placeholder: "Enter First Name",
                    allowedContactTypes: [
                        "Individual",
                    ]
                },
                {
                    control: "input",
                    name: "beneficiary_lastname",
                    label: "Company Name",
                    placeholder: "Enter Company Name",
                    allowedContactTypes: [
                        "Business",
                    ]
                },
                {
                    control: "input",
                    name: "beneficiary_lastname",
                    label: "Beneficiary Last Name",
                    placeholder: "Enter Last name",
                    allowedContactTypes: [
                        "Individual",
                    ]
                },
                {
                    control: "select",
                    name: "account_type",
                    label: "Account Type",
                    placeholder: "Select Acount Type",
                    options: [
                        {
                            "label": 'Checking',
                            "value": "checking",
                        },
                        {
                            "label": "Savings",
                            "value": "savings",
                        },
                    ]
                },
                {
                    control: "select",
                    name: "document_type",
                    label: "Document Type",
                    placeholder: "Select Document Type",
                    options: [
                        {
                            "label": "CED_CIU",
                            "value": "CED_CIU",
                        },
                        {
                            "label": "NIT",
                            "value": "NIT",
                        },
                        {
                            "label": "CED_EXT",
                            "value": "CED_EXT",
                        },
                        {
                            "label": "RUT",
                            "value": "RUT",
                        },
                        {
                            "label": "CUIT",
                            "value": "CUIT",
                        },
                        {
                            "label": "DNI",
                            "value": "DNI",
                        },
                        {
                            "label": "RUC",
                            "value": "RUC",
                        }
                    ]
                },
                {
                    control: "input",
                    name: "document_id",
                    label: "Document Id",
                    placeholder: "Enter Document Id",
                },
                {
                    control: "input",
                    name: "mobNumber",
                    label: "Mobile",
                    placeholder: "Beneficiary mobile number",
                    type: 'number'
                },
                {
                    control: "input",
                    name: "address",
                    label: "Address",
                    placeholder: "Beneficiary address",
                },
                {
                    control: "input",
                    name: "addressstate",
                    label: "State",
                    placeholder: "Address state",
                },
                {
                    control: "input",
                    name: "city",
                    label: "City",
                    placeholder: "Beneficiary city",
                },
                {
                    control: "input",
                    name: "postcode",
                    label: "PostCode / Zip",
                    placeholder: "Beneficiary post code",
                }
            ]
        },

    }

    const neo = {
        USD: {
            default: [{
                control: "input",
                name: "beneficiary_address",
                label: "Beneficiary Address",
                placeholder: "Enter Beneficiary Address",
            }, {
                control: "input",
                name: "bank_address",
                label: "Bank Address",
                placeholder: "Enter Bank Address",
            }, {
                control: "input",
                name: "bank_account_name",
                label: "Bank Name",
                placeholder: "Enter Bank Account Name",
            }, {
                control: "input",
                name: "swift",
                label: "Swfit code",
                placeholder: "Enter swift code",
            }]
        },
        GBP: {
            default: [{
                control: "input",
                name: "beneficiary_address",
                label: "Beneficiary Address",
                placeholder: "Enter Beneficiary Address",
            }, {
                control: "input",
                name: "bank_address",
                label: "Bank Address",
                placeholder: "Enter Bank Address",
            }, {
                control: "input",
                name: "bank_account_name",
                label: "Bank Name",
                placeholder: "Enter Bank Account Name",
            }, {
                control: "input",
                name: "swift",
                label: "Swfit code",
                placeholder: "Enter swift code",
            }, {
                control: "input",
                name: "sort_code",
                label: "Sort Code",
                placeholder: "Enter Sort Code",
            }]
        },
        EUR: {
            default: [{
                control: "input",
                name: "beneficiary_address",
                label: "Beneficiary Address",
                placeholder: "Enter Beneficiary Address",
            }, {
                control: "input",
                name: "bank_address",
                label: "Bank Address",
                placeholder: "Enter Bank Address",
            }, {
                control: "input",
                name: "bank_account_name",
                label: "Bank Name",
                placeholder: "Enter Bank Account Name",
            }, {
                control: "input",
                name: "swift",
                label: "Swfit code",
                placeholder: "Enter swift code",
            }],
            sepa_bank_va: [
                {
                    control: "input",
                    name: "iban",
                    label: "IBAN",
                    placeholder: "IBAN",
                },
                {
                    control: "input",
                    name: "bic",
                    label: "BIC",
                    placeholder: "BIC",
                },
            ]
        },
    }

    switch (paymentGateway) {
        case 'PG-230824305141379':
            return (isUndefined(bitssa[currency]) ? null : (bitssa[currency][paymentCode] || bitssa[currency]['default']));
        case 'PG-230824305141380':
            return (isUndefined(directa24[currency]) ? null : (directa24[currency][paymentCode] || directa24[currency]['default']));
        case 'PG-230914305141379':
            return (isUndefined(openpayd[currency]) ? null : (openpayd[currency][paymentCode] || openpayd[currency]['default']));
        case 'PG-240321191632238':
            return (isUndefined(openpayd[currency]) ? null : (openpayd[currency][paymentCode] || openpayd[currency]['default']));
        case 'PG-231226142412429':
            return (isUndefined(neo[currency]) ? null : (neo[currency][paymentCode] || neo[currency]['default']));
        case 'PG-231017161345295':
            return (isUndefined(peer[currency]) ? null : (peer[currency][paymentCode] || peer[currency]['default']));
        case 'PG-231226142202982':
            return (isUndefined(dlocal[currency]) ? null : (dlocal[currency][paymentCode] || dlocal[currency]['default']));
        case 'PG-290124014234498':
            return yellowcard;
        case 'PG-240226201619253':
            return (isUndefined(bitso[currency]) ? null : (bitso[currency][paymentCode] || bitso[currency]['default']));
        case 'PG-240312134719876':
            return (isUndefined(tripleA[currency]) ? null : (tripleA[currency][paymentCode] || tripleA[currency]['default']));
        case 'PG-240416072834585':
            return (isUndefined(koywe[currency]) ? null : (koywe[currency][paymentCode] || koywe[currency]['default']));
        case 'PG-240702052513488':
            return (isUndefined(local_payment[currency]) ? null : (local_payment[currency][paymentCode] || local_payment[currency]['default']));
        default:
            return null;
    }
};

export default formFields;
