import React, { useState } from "react";

//Custom Components
import Login from "./Login";
import VerifyMFA from "./VerifyMFA";
import MFASetup from "./MFASetup";

const LoginFlow = (props) => {
    const { setToken } = props || {};
    const [userEmail, setUserEmail] = useState(false);
    const [mfaStatus, setMfaStatus] = useState(false);
    const [password, setPassword] = useState("");

    return <>
        {!userEmail && <Login setMfaStatus={setMfaStatus} setUserEmail={setUserEmail} setUserPassword={setPassword} />}
        {(userEmail && !mfaStatus) && <MFASetup email={userEmail} setMfaStatus={setMfaStatus} />}
        {(userEmail && mfaStatus) && <VerifyMFA email={userEmail} password={password} redirectLogin={true} setToken={setToken} />}
    </>;
};

export default LoginFlow;
