import React, { useState } from "react";

//Custom Components
import Signup from "./Signup";
import OTPVerify from "./OTPVerify";

const SignupFlow = (props) => {
    const [userEmail, setUserEmail] = useState(false);
    const [otpStatus, setOtpStatus] = useState(false);
    const [password, setPassword] = useState("");
    const [orgType, setOrgType] = useState("");

    return <>
        {!userEmail && <Signup setUserEmail={setUserEmail} setUserPassword={setPassword} setOrgType={setOrgType} />}
        {userEmail && <OTPVerify email={userEmail} setUserEmail={setUserEmail} orgType={orgType} password={password} reset={false} />}
    </>;
};

export default SignupFlow;
