import { Box, Flex } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Typography from '../../components/Common/Typography';
import { callApiWithToken } from '../../utils/http_common';
import Loader from '../../utils/Loader';
import Integration from './Cards/Integration';
import Teams from './Cards/Teams';
import WalletDetails from './Cards/WalletDetails';
import KybDetailsCard from './Cards/KybDetailsCard';
import CustomerService from "../../services/customerService";
import PersonalDetailsCard from './Cards/PersonalDetailsCard';
import Finance from './Cards/Finance';
import Currencies from './Cards/Currencies';
import LoadingSkeleton from './LoadingSkeleton';

const Settings = () => {
    const [tab, setTab] = useState("teams");
    const [apsTab, setApsTab] = useState("integration");
    const [guestCollectionsTab, setGuestCollectionsTab] = useState('kyb_details');
    const [integrationData, setIntegrationData] = useState({});
    const [teamsData, setTeamsData] = useState([]);
    const [joinRequests, setJoinRequests] = useState([]);
    const [loading, setLoading] = useState({});
    const [refreshData, setRefreshData] = useState(false);
    const [walletdata, setWalletData] = useState({});
    const [customerData, setCustomerData] = useState({});

    const customerType = useSelector((state) => state?.login?.userDetails?.cxRoles || [])[0];
    const orgType = useSelector((state) => state?.login?.orgDetails?.orgType || "default");
    const customerDetails = useSelector((state) => state?.login?.userDetails || []);

    const { customerType: kybType = "" } = useSelector((state) => state?.customer?.kybDetails) || {};
    let type = "KYB"
    if (kybType === 'individual') {
        type = "KYC"
    }

    const refreshChildData = () => {
        setRefreshData(prevState => !prevState);
    };

    const getCustomerData = async () => {
        setLoading(true);
        const { data: integrationData } = (await callApiWithToken.post("/organization/details", { impersonatedOrg: 'all' })) || {};
        const { data: teamsData } = (await callApiWithToken.get("/customer/team/list")) || {};
        const { customers = [], joinRequests = [] } = teamsData?.data || {};

        setIntegrationData(integrationData);
        setTeamsData(customers);
        setJoinRequests(joinRequests);
        setLoading(false);
        setWalletData(integrationData);
    };

    const orgTypeRenderSelector = ({ orgType, customerType }) => {
        if (['payout'].includes(orgType)) {
            return (
                <Flex mt={'40px'} ml={'40px'} gap={6}>
                    <Flex direction={'column'} gap={7} borderLeft={"3px solid"} borderLeftColor={"#1A4FD6"} py={'10px'} px={"10px"} h={"130px"} justify={"center"} w={"216px"}>
                        <Typography color="primary" colorweight={tab === 'teams' ? "500" : "200"} type='body' weight='bold' _hover={{ cursor: 'pointer' }} onClick={() => setTab("teams")}>Team Management</Typography >
                        <Typography color="primary" colorweight={tab === 'finance' ? "500" : "200"} type='body' weight='bold' _hover={{ cursor: 'pointer' }} onClick={() => setTab("finance")}>Finance</Typography >
                        <Typography color="primary" colorweight={tab === 'integration' ? "500" : "200"} type='body' weight='bold' _hover={{ cursor: 'pointer' }} onClick={() => setTab("integration")}>Integration</Typography >
                    </Flex>
                    {
                        (tab === "teams" &&
                            <Box px={"10"} bg={"#FFFFFF"} w={"full"} rounded={"9px"} minH={'600px'} border={'1px'} borderColor={"#E4E4E4"}>
                                <Teams team={teamsData} joinRequests={joinRequests} refreshData={refreshChildData} />
                            </Box>
                        ) ||
                        (tab === "finance" &&
                            <Finance data={integrationData} />
                        ) ||
                        (tab === "integration" &&
                            <Box px={"10"} bg={"#FFFFFF"} w={"full"} rounded={"9px"} minH={'600px'} border={'1px'} borderColor={"#E4E4E4"}>
                                <Integration data={integrationData} />
                            </Box>
                        )
                    }
                </Flex>
            );
        } else if (['collections'].includes(orgType) && !['guest'].includes(customerType)) {
            return (
                <Flex mt={'40px'} ml={'40px'} gap={6}>
                    <Flex direction={'column'} gap={7} borderLeft={"3px solid"} borderLeftColor={"#1A4FD6"} py={'10px'} px={"10px"} h={"150px"} justify={"center"} w={"216px"}>
                        <Typography color="primary" colorweight={tab === 'teams' ? "500" : "200"} type='body' weight='bold' _hover={{ cursor: 'pointer' }} onClick={() => setTab("teams")}>Team Management</Typography >
                        <Typography color="primary" colorweight={tab === 'wallet' ? "500" : "200"} type='body' weight='bold' _hover={{ cursor: 'pointer' }} onClick={() => setTab("wallet")}>Wallet Details</Typography >
                        <Typography color="primary" colorweight={tab === 'integration' ? "500" : "200"} type='body' weight='bold' _hover={{ cursor: 'pointer' }} onClick={() => setTab("integration")}>Integration</Typography >
                    </Flex>
                    <Box px={"10"} bg={"#FFFFFF"} w={"full"} rounded={"9px"} minH={'600px'} border={'1px'} borderColor={"#E4E4E4"}>
                        {tab === 'teams' ?
                            <Teams team={teamsData} joinRequests={joinRequests} refreshData={refreshChildData} /> :
                            tab === 'wallet' ? <WalletDetails data={walletdata} /> :
                                <Integration data={integrationData} />
                        }
                    </Box>
                </Flex>
            );
        } else if (['collections'].includes(orgType) && ['guest'].includes(customerType)) {
            return (
                <Flex mt={'40px'} ml={'40px'} gap={6}>
                    <Flex direction={'column'} gap={7} borderLeft={"3px solid"} borderLeftColor={"#1A4FD6"} py={'10px'} px={"10px"} h={"100px"} justify={"center"} w={"216px"}>
                        <Typography color="primary" colorweight={guestCollectionsTab === 'kyb_details' ? "500" : "200"} type='body' weight='bold' _hover={{ cursor: 'pointer' }} onClick={() => setGuestCollectionsTab("kyb_details")}>{type} Details</Typography >
                        <Typography color="primary" colorweight={guestCollectionsTab === 'personal_details' ? "500" : "200"} type='body' weight='bold' _hover={{ cursor: 'pointer' }} onClick={() => setGuestCollectionsTab("personal_details")}>Personal Details</Typography >
                    </Flex>
                    <Box px={"10"} bg={"#FFFFFF"} w={"full"} rounded={"9px"} border={'1px'} borderColor={"#E4E4E4"}>
                        {guestCollectionsTab === 'kyb_details' ?
                            <KybDetailsCard customerDetails={customerData} kybType={type} /> :
                            <PersonalDetailsCard customerDetails={customerDetails} />
                        }
                    </Box>
                </Flex>
            );
        } else {
            return (

                <Flex mt={'40px'} ml={'40px'} gap={6}>

                <Flex direction={'column'} gap={7} borderLeft={"3px solid"} borderLeftColor={"#1A4FD6"} py={'7px'} px={"7px"} h={"100px"} justify={"center"} w={"216px"}>
                    <Typography color="primary" colorweight={apsTab === 'integration' ? "500" : "200"} type='body' weight='bold' _hover={{ cursor: 'pointer' }} onClick={() => setApsTab("integration")}>Integration</Typography >
                        {process.env.REACT_APP_NODE_ENV  === "development" && <Typography color="primary" colorweight={apsTab === 'currencies' ? "500" : "200"} type='body' weight='bold' _hover={{ cursor: 'pointer' }} onClick={() => setApsTab("currencies")}>Currencies</Typography >}
                </Flex>
                <Box px={"10"} bg={"#FFFFFF"} w={"full"} rounded={"9px"} minH={'600px'} border={'1px'} borderColor={"#E4E4E4"}>
                    {apsTab === 'integration' ?
                    <Integration data={integrationData} /> :
                    <Currencies />
                    }
                </Box>

                </Flex>


            );
        }
    };

    useEffect(() => {
        getCustomerData()
    }, [refreshData]);

    useEffect(() => {
        const fetchData = async () => {
            const { cxId = '' } = customerDetails;
            const { data: response = {} } = await CustomerService.getKybDetails({ cxId }) || {};
            setCustomerData(response?.data || {});
        }
        if (['collections'].includes(orgType) && ['guest'].includes(customerType)) {
            fetchData();
        }
    }, [customerDetails, customerType, orgType]);

    return (
        loading ? <LoadingSkeleton orgType={orgType} customerType={customerType}/> :
            <Box px={8} py={5} bg={'#F2F2F2'} minH={'100vh'} >
                <Typography color="default" type="largeheader" weight="bold">Settings</Typography>
                {
                    <>
                        {
                            orgTypeRenderSelector({ orgType, customerType })
                        }
                    </>
                }

            </Box >
    );
};

export default Settings;