import React, { useCallback, useState } from "react";
import { Box, Flex, Image, Text, useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useGoogleLogin } from "@react-oauth/google";

//Custom Components and functions
import { validateEmail } from "../../utils/validations";
import Loader from "../../components/Loader";
import Input from "../../components/LoginComponents/InputComponents/TextField";
import PasswordInput from "../../components/LoginComponents/InputComponents/PasswordInput";
import Button from "../../components/LoginComponents/Button";
import Divider from "../../components/LoginComponents/Divider";
import { singleSignOn, check2FA } from "../../store/slices/loginSlices";

//css
import "./PasswordLogin.css"

//icons
import { FcGoogle } from "react-icons/fc";
import TransfiLogo from "../../assets/transfi-logo.svg"
import HelmetWrapper from "../../utils/HelmetWrapper";

const Login = (props) => {
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [buttonLoader, setButtonLoader] = useState({ googleButton: false, normalButton: false });
  const [viewPassword, setViewPassword] = useState(false);
  const { setMfaStatus, setUserEmail, setUserPassword } = props || {};

  const handleEmailInputChange = (e) => {
    setEmail(e.target.value);
  };

  const handleToast = (title, type) => {
    toast({
      title,
      status: type,
      duration: 5000,
      isClosable: true
    });
  };

  const verifySingleSignOn = useCallback((token) => {
    dispatch(singleSignOn({ token })).then(res => res?.payload).then(res => {
      const { data = false, error = false, status: responseStatus } = res;
      if (data) {
        const { message, status } = data;
        if (status === 'created') {
          handleToast(message, 'success');
          navigate('/fill-details');
        }
      }
      else {
        const { message, status } = error;
        handleToast(message, 'error');
        if (responseStatus === 404)
          navigate('/signup');
      }
      setIsLoading(false);
      setButtonLoader(prev => ({
        ...prev,
        googleButton: false
      }));
    });
  }, [dispatch]);

  const handleChangePassword = (e) => {
    setPassword(e.target.value);
  }

  const signInUsingGoogle = useGoogleLogin({
    onSuccess: async (response) => {
      try {
        verifySingleSignOn(response?.access_token);
      } catch (e) {
        handleToast(e?.message || "Something went wrong", 'error');
        setIsLoading(false);
        setButtonLoader(prev => ({
          ...prev,
          googleButton: false
        }));
      }
    },
    onError: (error) => {
      handleToast(error.message, 'error');
      setButtonLoader(prev => ({
        ...prev,
        googleButton: false
      }));
    },
  });

  const check2fa = async () => {
    if (!validateEmail(email)) {
      handleToast("Please enter valid email", 'error');
      return;
    }

    if (password === "" || !password) {
      handleToast("Please fill password to continue", 'error');
      return;
    }

    try {
      setButtonLoader(prev => ({
        ...prev,
        normalButton: true
      }));
      dispatch(check2FA({ email, accountDetails: password }))
        .then(res => res.payload)
        .then(res => {
          const { data, error, status: responseStatus } = res;
          if (data) {
            setIsLoading(true);
            const { status = false, mfaStatus, message = '' } = data;
            if (status === 'created') {
              handleToast(message, 'success');
              navigate('/fill-details');
              setIsLoading(false);
            }
            else if (status) {
              if (mfaStatus) {
                handleToast("Password Verified!, Verify 2FA to move forward", 'success');
              } else {
                handleToast("Password Verified!, Setup and verify 2FA to move forward", 'success');
              }
              setMfaStatus(mfaStatus);
              setUserEmail(email);
              setUserPassword(password);
            }
            else {
              throw new Error("Something Went Wrong!");
            }
          }
          else if (error) {
            const { message } = error;
            handleToast(message || "Something went wrong", 'error');
            if (responseStatus === 404 && message === "User not found, Please Signup")
              navigate('/signup');
          }
          else {
            throw new Error("Something Went Wrong!");
          }
          setButtonLoader(prev => ({
            ...prev,
            normalButton: false
          }));
        });
    } catch (e) {
      handleToast(e?.response?.data?.error?.message || "Something went wrong", 'error');
      setButtonLoader(prev => ({
        ...prev,
        normalButton: false
      }));
    }
  }

  return (
    <>
    <HelmetWrapper title="Transfi Pay Login" description="Transfi Pay Login Page"/>
    {isLoading ? <Loader /> :
      <Flex bg={'white'} alignItems={'center'} h={'full'} w={'full'}>
        <Box bg={'white'} h={"full"} w={'50%'}>
          <Box>
            <div className="pa-login-sub-card">
              <div className="pa-login-header">
                <Image src={TransfiLogo} alt="logo" />
                <h1 className="pa-login-header-text">Welcome To TransFi</h1>
                <Button
                  onClickHandler={() => {
                    signInUsingGoogle();
                    setButtonLoader(prev => ({
                      ...prev,
                      googleButton: true
                    }));
                  }}
                  className={"pa-sign-in-with-google-button"}
                  isLoading={buttonLoader.googleButton} >
                  <FcGoogle style={{ width: '22px', height: '22px' }} />
                  Login with Google
                </Button>
              </div>

              <div className="pa-login-body">
                <Divider>
                  or login with email
                </Divider>
                <p>Work Email</p>
                <Input
                  type={"email"}
                  placeholder={"Enter your email address"}
                  inputChangeHandler={handleEmailInputChange}
                  value={email}
                />
                <p>Password</p>
                <PasswordInput
                  placeholder={"Enter your password"}
                  inputChangeHandler={handleChangePassword}
                  value={password}
                  viewPassword={viewPassword}
                  setViewPassword={setViewPassword}
                />
                <p className="pa-forget-password-enabled" onClick={() => navigate('/account/login-pwd/reset')}>Forgot Password?</p>
                <p>Don't have an account? <span className="pa-forget-password-enabled" onClick={() => navigate('/signup')}>Signup</span></p>
                <Button onClickHandler={check2fa} className={"pa-button"} isLoading={buttonLoader.normalButton} >
                  Next
                </Button>
              </div>
            </div>
          </Box>
        </Box>
        <Flex className="gradient-background" flexDirection={'column'} justify={'space-between'} w={'full'} maxH={'100vh'} overflow={'hidden'}>
          <Box ml={'80px'} mt={'100px'}>
            <Text color={'#092C4C'} fontFamily={'DM Sans'} fontWeight={'700'} fontSize={'48px'}>Borderless <Text as={'span'} color={'#1A4FD6'}>payments</Text>,</Text>

            <Text color={'#092C4C'} fontFamily={'DM Sans'} fontWeight={'700'} fontSize={'48px'}>boundless <Text as={'span'} color={'#1A4FD6'}>potential</Text></Text>
          </Box>
          <Flex w={'full'} justify={'flex-end'}>
            <Image src="/assets/login.png" maxH={'809px'} maxW={'992px'} />
          </Flex>
        </Flex>
      </Flex>}
      </>
  );
};

export default Login;
